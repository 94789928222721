
import { defineComponent } from 'vue'
import { insureDeductionDetailExport } from '@/api/export'
import { insureDeductionDetail, insureDeductionDetailListDto, InsureProjectDto } from '@/api/insure-deduction'
export default defineComponent({
    name: 'InsureDeductionDetail',
    data () {
        return {
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as insureDeductionDetailListDto[],
            month: '',
            insureProject: {} as InsureProjectDto
        }
    },
    created () {
        this.getInsureRecord()
    },
    methods: {
        getInsureRecord () {
            insureDeductionDetail({
                id: this.$route.params.id,
                pageSize: this.pageSize,
                pageNum: this.pageNum
            }).then(res => {
                this.tableData = res.list
                this.total = res.totalCount
                this.insureProject = res.insureProject as InsureProjectDto
                console.log(this.insureProject, 'insureProject')
                console.log(this.insureProject, 'insureProjectinsureProject')
            })
        },
        handleCurrentChange () {
            this.getInsureRecord()
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        async onExport () {
            const result = await insureDeductionDetailExport({
                ids: this.$route.params.id + ''
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                    '/common/download?delete=true&fileName=' +
                    result

            this.$message.success('导出成功！')
        }
    }
})
